<template>
    <!-- 客户分组 -->
    <div class="customerGroup">
        <div class="table">
            <div class="table-operation">
                <el-button type="warning" icon="el-icon-plus"
                    @click="dialogVisible = true, dialogType = 1, form = {}">新增</el-button>
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <Table :data="tableData" :total="total" v-loading="loading"
                @size-change="pageNum = $event, loading = true, getList()"
                @current-change="page = $event, loading = true, getList()">
                <el-table-column label="分组名称" prop="name" align="center"></el-table-column>
                <el-table-column label="执行折扣" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.discount }}折</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini"
                            @click="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </Table>
        </div>
        <!-- 新增 -->
        <el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
            v-el-drag-dialog width="30%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="分组名称">
                    <el-input v-model="form.name" placeholder="请输入分组名称"></el-input>
                </el-form-item>
                <el-form-item label="执行折扣">
                    <el-input v-model="form.discount" placeholder="请输入0-1的小数"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="warning" @click="confirmFn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            tableData: [],
            loading: true,
            page: 1,
            pageNum: 10,
            total: 0,
            // 新增、编辑弹窗
            dialogType: 1,//1新增2编辑
            dialogVisible: false,
            form: {
                name: '',
                discount: ''
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post(`erp/v1/customer_group/lst`, {
                page: this.page,
                pageNum: this.pageNum,
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows
                    this.tableData.forEach(item => {
                        item.discount = Number(item.discount).toFixed(2)
                    });
                    this.total = data.total
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 编辑弹窗
        openDialog(item) {
            this.form.id = item.id
            this.form.name = item.name
            this.form.discount = item.discount
        },
        // 确定
        confirmFn() {
            if (!this.form.name) {
                this.$message.error('请输入分组名称');
                return
            }
            if (!this.form.discount) {
                this.$message.error('请输入执行折扣');
                return
            }
            let url = this.dialogType == 1 ? '/erp/v1/customer_group/add' : '/erp/v1/customer_group/modify'
            this.$http.post(`${url}`, this.form).then((res) => {
                const { code, msg } = res.data
                if (code == 200) {
                    this.$message.success(msg);
                    this.dialogVisible = false
                    this.getList()
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 删除
        removeFn(item) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`erp/v1/customer_group/delete`, {
                    id: item.id
                }).then((res) => {
                    const { code, msg } = res.data
                    if (code == 200) {
                        this.$message.success(msg);
                        this.getList()
                    } else {
                        this.$message.error(msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    },
}
</script>
    
<style lang="scss" scoped></style>